
export default {
  type: "events",
  title: null,
  color: null,
  excerpt: "",
  relationshipNames: [
    "organization",
    "allowedLocations",
    "serviceCenter",
    "establishment",
    "cafeteria",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  allowedLocations: [],
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },
};

<template>
  <div class="container-fluid mt-5">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <template slot="header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("COMMON.EVENTS") }}
              <i class="fa fa-spinner fa-spin" v-if="loading"></i>
            </h3>
          </div>
          <div class="col-6 text-right">
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_EVENTS)"
              :objectType="'events'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </template>

      <div class="card-body">
        <div class="filters">
          <div class="filters-select">
            <service-center-selector
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
              @serviceCenterChanged="
                (serviceCenterId) => (selectedServiceCenter = serviceCenterId)
              "
            />
            <establishment-selector
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
              @establishmentChanged="
                (establishmentId) => (selectedEstablishment = establishmentId)
              "
            />
            <cafeteria-selector
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
              @cafeteriaChanged="
                (cafeteriaId) => (selectedCafeteria = cafeteriaId)
              "
            />
            <el-select
              v-model="selectedCategory"
              :placeholder="`${$t('COMMON.CATEGORY')}`"
            >
              <el-option :value="null" :label="$t('COMMON.ALL_CATEGORIES')">
              </el-option>
              <el-option
                v-for="category in EVENT_CATEGORIES"
                :key="category"
                :value="category"
                :label="$t(`EVENTS.EVENT_CATEGORY_${category}`)"
              />
            </el-select>
          </div>
          <div class="filters-buttons">
            <base-button @click="showMyEvents">
              {{ $t("EVENTS.SHOW_MY_EVENTS") }}
            </base-button>
            <base-button @click="clearFilter">
              {{ $t("EVENTS.SHOW_ALL") }}
            </base-button>
          </div>
        </div>
        <div class="calendar-wrapper">
          <full-calendar
            :events="events"
            :plugins="calendarPlugins"
            :editable="false"
            :locale="$i18n.locale"
            :defaultView="defaultView"
            :theme="false"
            :header="headerToolbar"
            :buttonText="buttonText"
            :defaultDate="defaultDate"
            contentHeight="auto"
            ref="fullCalendar"
            @dateClick="handleDayClick"
            @eventClick="handleEventClick"
          >
          </full-calendar>
        </div>
      </div>
    </card>

    <div class="reference">
      <div
        class="reference-item"
        v-for="category in EVENT_CATEGORIES"
        :key="category"
        :value="category"
      >
        <ul>
          <li
            :style="{ backgroundColor: EVENT_CATEGORIES_COLORS[category] }"
          ></li>
          <li>{{ $t(`EVENTS.EVENT_CATEGORY_${category}`) }}</li>
        </ul>
      </div>
    </div>

    <modal
      class="event-add-modal"
      :show="showEventFormModal"
      :showClose="true"
      @close="closeEventsModal"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div class="modal-body-inner" v-if="!!selectedEvent">
        <div class="header">
          <h2>
            {{
              selectedEvent.id
                ? $t("EVENTS.EDIT_EVENT")
                : $t("EVENTS.ADD_EVENT")
            }}
          </h2>
          <button @click="closeEventsModal">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="body">
          <!-- Organization -->
          <base-input
            :label="`${$t('COMMON.ORGANIZATION')} (*)`"
            :placeholder="$t('COMMON.ORGANIZATION')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
          >
            <organization-selector
              :organization="selectedEvent.organization.id"
              :filterable="true"
              :showAll="false"
              @organizationChanged="
                (organizationId) => {
                  selectedEvent.organization.id = organizationId;
                }
              "
              :disabled="!!selectedEvent.id"
            />
          </base-input>

          <base-input
            :label="`${$t('COMMON.CATEGORY')} (*)`"
            :placeholder="$t('COMMON.CATEGORY')"
          >
            <el-select
              v-model="selectedEvent.category"
              :placeholder="`${$t('COMMON.CATEGORY')}`"
            >
              <el-option
                v-for="category in EVENT_CATEGORIES"
                :key="category"
                :value="category"
                :label="$t(`EVENTS.EVENT_CATEGORY_${category}`)"
              />
            </el-select>
          </base-input>

          <div class="row">
            <base-input
              class="col-6"
              :label="`${$t('EVENTS.STARTS_AT')} (*)`"
              :placeholder="$t('EVENTS.STARTS_AT')"
            >
              <flat-picker
                :config="{
                  allowInput: true,
                  locale: $flatPickrLocale(),
                  enableTime: true,
                }"
                class="form-control datepicker"
                v-model="selectedEvent.starts_at"
              >
              </flat-picker>
            </base-input>
            <base-input
              class="col-6"
              :label="`${$t('EVENTS.ENDS_AT')} (*)`"
              :placeholder="$t('EVENTS.ENDS_AT')"
            >
              <flat-picker
                :config="{
                  allowInput: true,
                  locale: $flatPickrLocale(),
                  enableTime: true,
                }"
                class="form-control datepicker"
                v-model="selectedEvent.ends_at"
              >
              </flat-picker>
            </base-input>
          </div>

          <base-input
            :label="`${$t('EVENTS.TITLE')} (*)`"
            :placeholder="$t('EVENTS.TITLE')"
            v-model="selectedEvent.title"
          >
          </base-input>

          <h2 class="mt-1">
            {{ `${$t("COMMON.EXCERPT")} (*)` }}
          </h2>
          <html-editor v-model="selectedEvent.excerpt"> </html-editor>

          <base-input
            :label="`${$t('COMMON.SERVICECENTER')} `"
            :placeholder="$t('COMMON.SERVICECENTER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
          >
            <service-center-selector
              :serviceCenter="
                selectedEvent.serviceCenter
                  ? selectedEvent.serviceCenter.id
                  : null
              "
              :filterable="true"
              :showAll="false"
              :filterOrganization="selectedEvent.organization.id"
              :disabled="selectedEvent.locked"
              @serviceCenterChanged="
                (serviceCenter) => {
                  selectedEvent.serviceCenter = {
                    type: 'service-centers',
                    id: serviceCenter,
                  };
                  selectedEvent.establishment = {
                    type: 'establishments',
                    id: null,
                  };
                  selectedEvent.cafeteria = {
                    type: 'cafeterias',
                    id: null,
                  };
                }
              "
            />
          </base-input>

          <base-input
            :label="`${$t('COMMON.ESTABLISHMENT')} `"
            :placeholder="$t('COMMON.ESTABLISHMENT')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
          >
            <establishment-selector
              :establishment="
                selectedEvent.establishment
                  ? selectedEvent.establishment.id
                  : null
              "
              :filterable="true"
              :showAll="false"
              :filterOrganization="selectedEvent.organization.id"
              :filterServiceCenter="selectedEvent.serviceCenter?.id"
              :disabled="!selectedEvent.serviceCenter?.id"
              @establishmentChanged="
                (establishment) => {
                  selectedEvent.establishment = {
                    type: 'establishments',
                    id: establishment,
                  };
                  selectedEvent.cafeteria = {
                    type: 'cafeterias',
                    id: null,
                  };
                }
              "
            />
          </base-input>

          <base-input
            :label="`${$t('COMMON.CAFETERIA')} `"
            :placeholder="$t('COMMON.CAFETERIA')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
          >
            <cafeteria-selector
              :disabled="!selectedEvent.establishment?.id"
              :cafeteria="
                selectedEvent.cafeteria ? selectedEvent.cafeteria.id : null
              "
              :filterable="true"
              :showAll="false"
              :filterOrganization="selectedEvent.organization.id"
              :filterServiceCenter="selectedEvent.serviceCenter?.id"
              :filterEstablishment="selectedEvent.establishment?.id"
              @cafeteriaChanged="
                (cafeteria) => {
                  selectedEvent.cafeteria = {
                    type: 'cafeterias',
                    id: cafeteria,
                  };
                }
              "
            />
          </base-input>
        </div>
        <div class="footer">
          <base-button
            type="button"
            class="btn btn-sm btn-primary cancel"
            native-type="submit"
            @click="closeEventsModal"
          >
            {{ $t("COMMON.CANCEL") }}
          </base-button>
          <base-button
            v-if="!selectedEvent.id"
            type="button"
            class="btn btn-sm btn-primary add"
            native-type="submit"
            @click="handleAdd"
          >
            {{ $t("EVENTS.ADD_EVENT") }}
          </base-button>
          <base-button
            v-if="!!selectedEvent.id"
            type="button"
            class="btn btn-sm btn-primary edit"
            native-type="submit"
            @click="handleEdit"
          >
            {{ $t("EVENTS.EDIT_EVENT") }}
          </base-button>
        </div>
      </div>
    </modal>

    <modal
      class="event-view-modal"
      :show="showEventViewModal"
      @close="closeEventsModal"
      :showClose="true"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div class="modal-body-inner" v-if="!!selectedEvent">
        <div
          :style="{ 'background-color': selectedEvent.color }"
          class="header"
        >
          <h2>
            {{ selectedEvent.title }}
          </h2>
          <button @click="closeEventsModal">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="body">
          <dl class="row">
            <dt class="col-sm-4">{{ $t("COMMON.CATEGORY") }}</dt>
            <dd class="col-sm-8">
              {{ $t(`EVENTS.EVENT_CATEGORY_${selectedEvent.category}`) }}
            </dd>
          </dl>

          <dl class="row" v-if="selectedEvent.serviceCenter">
            <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
            <dd class="col-sm-8">
              <object-link :object="selectedEvent.serviceCenter" />
            </dd>
          </dl>

          <dl class="row" v-if="selectedEvent.establishment">
            <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
            <dd class="col-sm-8">
              <object-link :object="selectedEvent.establishment" />
            </dd>
          </dl>

          <dl class="row" v-if="selectedEvent.cafeteria">
            <dt class="col-sm-4">{{ $t("COMMON.CAFETERIE") }}</dt>
            <dd class="col-sm-8">
              <object-link :object="selectedEvent.cafeteria" />
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-4">{{ $t("EVENTS.STARTS_AT") }}</dt>
            <dd class="col-sm-8">
              {{ $timeZoneDateFormat(selectedEvent.starts_at) }}
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-4">{{ $t("EVENTS.ENDS_AT") }}</dt>
            <dd class="col-sm-8">
              {{ $timeZoneDateFormat(selectedEvent.ends_at) }}
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
            <dd class="col-sm-8" v-html="selectedEvent.excerpt"></dd>
          </dl>
        </div>
        <div class="footer">
          <base-button
            type="button"
            class="btn btn-sm btn-primary cancel"
            native-type="submit"
            @click="closeEventsModal"
          >
            {{ $t("COMMON.CANCEL") }}
          </base-button>
          <base-button
            type="button"
            class="btn btn-sm btn-primary edit"
            native-type="submit"
            @click="openEditForm"
          >
            {{ $t("EVENTS.EDIT_EVENT") }}
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import moment from "moment";
import swal from "sweetalert2";
import format from "date-fns/format";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import permissions from "@/constants/permissions";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import defaultEvent from "./defaultEvent";
import { EVENT_CATEGORIES, EVENT_CATEGORIES_COLORS } from "@/constants/events";
import { Select, Option } from "element-ui";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import { mapGetters } from "vuex";

const today = new Date();
export default {
  name: "events-page",

  components: {
    FullCalendar,
    NotificationSubscription,
    flatPicker,
    HtmlEditor,
    OrganizationSelector,
    ServiceCenterSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  computed: {
    ...mapGetters({
      me: "profile/me",
    }),
  },

  data() {
    return {
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
      ],
      defaultView: "dayGridMonth",
      year: today.getFullYear(),
      today: format(today, "MMM dd"),
      defaultDate: format(today, "yyyy-MM-dd"),
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek,listMonth,listWeek",
      },
      buttonText: {
        today: this.$t("COMMON.TODAY"),
        month: this.$t("COMMON.MONTH"),
        week: this.$t("COMMON.WEEK"),
        day: this.$t("COMMON.DAY"),
        prev: this.$t("COMMON.PREV"),
        next: this.$t("COMMON.NEXT"),
        dayGridMonth: this.$t("EVENTS.GRID_MONTH"),
        dayGridWeek: this.$t("EVENTS.GRID_WEEK"),
        listMonth: this.$t("EVENTS.LIST_MONTH"),
        listWeek: this.$t("EVENTS.LIST_WEEK"),
      },
      events: [],
      selectedEvent: null,
      showEventFormModal: false,
      showEventViewModal: false,
      loading: false,
      EVENT_CATEGORIES,
      EVENT_CATEGORIES_COLORS,
      selectedServiceCenter: null,
      selectedEstablishment: null,
      selectedCafeteria: null,
      selectedCategory: null,
      moment: moment,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    renderEventContent(arg) {
      return {
        html: `
          <div class="fc-list-event-content">
            <div class="fc-list-event-title">${arg.event.title}</div>
            <div class="fc-list-event-date">${
              arg.event.start.toISOString().split("T")[0]
            }</div>
          </div>
        `,
      };
    },

    handleDayClick({ date }) {
      if (!this.$currentUserCan(permissions.PERM_CREATE_EVENTS)) {
        return;
      }

      if (moment(date) < moment(new Date())) {
        return;
      }

      const selectedEvent = cloneDeep(defaultEvent);
      selectedEvent.starts_at = moment(date).toISOString();
      selectedEvent.ends_at = moment(date).endOf("day").toISOString();
      this.selectedEvent = selectedEvent;
      this.showEventFormModal = true;
    },

    handleEventClick({ event }) {
      const eventId = event.id;

      if (!this.$currentUserCan(permissions.PERM_EDIT_EVENTS)) {
        return;
      }

      const events = this.$store.getters["events/list"];
      const selectedEvent = cloneDeep(
        events.find((item) => item.id == eventId)
      );

      this.selectedEvent = cloneDeep(selectedEvent);
      this.showEventViewModal = true;
    },

    openEditForm() {
      this.showEventFormModal = true;
      this.showEventViewModal = false;
    },

    closeEventsModal() {
      this.selectedEvent = null;
      this.showEventFormModal = false;
      this.showEventViewModal = false;
    },

    async getList() {
      this.loading = true;
      try {
        let params = {
          sort: "starts_at",
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "organization,serviceCenter,establishment,cafeteria",
        };

        if (this.selectedServiceCenter) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              serviceCenter: this.selectedServiceCenter,
            },
          };
        }

        if (this.selectedEstablishment) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              establishment: this.selectedEstablishment,
            },
          };
        }

        if (this.selectedCafeteria) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              cafeteria: this.selectedCafeteria,
            },
          };
        }
        if (this.selectedCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              category: this.selectedCategory,
            },
          };
        }

        await this.$store.dispatch("events/list", params);
        const events = this.$store.getters["events/list"];
        this.events = events.map((event) => {
          return {
            id: event.id,
            title: event.title,
            start: moment(event.starts_at).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(event.ends_at).format("YYYY-MM-DDTHH:mm:ss"),
            backgroundColor: event.color,
          };
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    showMyEvents() {
      if (this.me.restrict_to_service_centers) {
        this.selectedServiceCenter = this.me.allowedServiceCenters();
      }
      if (this.me.restrict_to_establishments) {
        this.selectedEstablishment = this.me.allowedEstablishments();
      }
      if (this.me.restrict_to_cafeterias) {
        this.selectedCafeteria = this.me.allowedCafeterias();
      }
    },
    clearFilter() {
      this.selectedServiceCenter = null;
      this.selectedEstablishment = null;
      this.selectedCafeteria = null;
    },

    async handleAdd() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      let eventData = cloneDeep(this.selectedEvent);
      eventData = this.$fillUserOrganizationData(eventData);
      eventData = this.$fillUserCafeteriasData(eventData);
      eventData.color = EVENT_CATEGORIES_COLORS[eventData.category];

      if (eventData.serviceCenter) {
        if (!eventData.serviceCenter.id) {
          delete eventData.serviceCenter;
        }
      }
      if (eventData.establishment) {
        if (!eventData.establishment.id) {
          delete eventData.establishment;
        }
      }
      if (eventData.cafeteria) {
        if (!eventData.cafeteria.id) {
          delete eventData.cafeteria;
        }
      }

      try {
        await this.$store.dispatch("events/add", eventData);
        this.$notify({
          type: "success",
          message: this.$t("EVENTS.EVENT_ADDED"),
        });
        this.getList();
        this.closeEventsModal();
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        swal.close();
      }
    },

    async handleEdit() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      let eventData = cloneDeep(this.selectedEvent);
      eventData = this.$fillUserOrganizationData(eventData);
      eventData = this.$fillUserCafeteriasData(eventData);
      eventData.color = EVENT_CATEGORIES_COLORS[eventData.category];

      if (eventData.serviceCenter) {
        if (!eventData.serviceCenter.id) {
          delete eventData.serviceCenter;
        }
      }
      if (eventData.establishment) {
        if (!eventData.establishment.id) {
          delete eventData.establishment;
        }
      }
      if (eventData.cafeteria) {
        if (!eventData.cafeteria.id) {
          delete eventData.cafeteria;
        }
      }

      try {
        await this.$store.dispatch("events/update", eventData);
        const event = this.$store.getters["events/event"];
        this.$notify({
          type: "success",
          message: this.$t("EVENTS.EVENT_UPDATED"),
        });
        this.getList();
        this.selectedEvent = event;
        this.showEventFormModal = false;
        this.showEventViewModal = true;
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        swal.close();
      }
    },
  },

  watch: {
    selectedServiceCenter: {
      handler: "getList",
      immediate: true,
    },
    selectedEstablishment: {
      handler: "getList",
      immediate: true,
    },
    selectedCafeteria: {
      handler: "getList",
      immediate: true,
    },
    selectedCategory: {
      handler: "getList",
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@/assets/sass/core/vendors/fullcalendar";
</style>
